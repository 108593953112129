import { Layout } from 'antd';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import userContext from '@/helpers/userContext';

import Menu from './Menu';
import UserMenu from './UserMenu';

require('./styles.less');

const { Header } = Layout;

const HeaderContainer = (props) => {
  const { isLanding } = props;
  const { pathname } = useRouter();
  const { currentUser } = useContext(userContext);

  return (
    <Header className={classNames('header', { header_landing: isLanding })}>
      <div className="header__wrapper">
        <Link href="/">
          <a>
            <img className="header__logo" src="/static/logo.svg" width="171" height="22" alt="zavod.online logo" />
          </a>
        </Link>
        <Menu pathname={pathname} />
        <UserMenu data={currentUser} />
      </div>
    </Header>
  );
};

export default HeaderContainer;
