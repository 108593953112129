import { Layout } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import Link from 'next/link';

require('./styles.less');

const { Footer } = Layout;

const FooterContainer = (props) => {
  const { isLanding } = props;
  const currentYear = moment().format('YYYY');

  return (
    <Footer className={classNames('footer', { footer_landing: isLanding })}>
      <div className="footer__content footer__content_left">
        <div className="footer__logo">zavod.online</div>
        <div className="footer__social-networks">
          {/* <a
            href="https://www.facebook.com/zavod.online/"
            target="_blank"
            rel="noreferrer"
            className="footer__social-network footer__social-network_fb"
          >
            zavod.online в Facebook
          </a>
          <a
            href="https://www.instagram.com/zavod.online/"
            target="_blank"
            rel="noreferrer"
            className="footer__social-network footer__social-network_instagram"
          >
            zavod.online в Instagram
          </a> */}
          <a
            href="https://vk.com/zavod.online"
            target="_blank"
            rel="noreferrer"
            className="footer__social-network footer__social-network_vk"
          >
            zavod.online в ВКонтакте
          </a>
        </div>
        <a href="mailto:info@zavod.online" target="_blank" rel="noreferrer" className="footer__email-link">
          info@zavod.online
        </a>
      </div>
      <div className="footer__content footer__content_right">
        <Link href="/documents/privacy-policy">
          <a>Политика конфиденциальности</a>
        </Link>
        <Link href="/documents/consent-to-processing-data">
          <a>Согласие на обработку персональных данных</a>
        </Link>
        <Link href="/documents/rules">
          <a>Пользовательское соглашение</a>
        </Link>
        <div>
          Все права защищены. Использование материалов с сайта только с согласия правообладателей.
          <span className="footer__copyright">zavod.online © {currentYear}</span>
        </div>
      </div>
    </Footer>
  );
};

export default FooterContainer;
