// import 'core-js/stable';
import 'whatwg-fetch';

import { useMount } from 'ahooks';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import ExecutionEnvironment from 'exenv';
import moment from 'moment';
import { YMInitializer } from 'react-yandex-metrika';
// Import App from 'next/app';
import { CacheProvider } from 'rest-hooks';

import { Footer, Header } from '@/containers';
import { GlobalProvider } from '@/helpers/globalContext';
import loadFonts from '@/helpers/loadFonts';
import { init } from '@/helpers/sentry';
import { UserProvider } from '@/helpers/userContext';

require('@/assets/global.less');

moment.locale('ru');

init();

const CLIENT_ONLY = [
  '/my-portfolio',
  '/my-portfolio/edit',
  '/my-profile',
  '/my-profile/edit',
  '/my-orders',
  '/my-responses',
  '/order',
  '/order/[id]/rate',
  '/executors'
];

const MyApp = (props) => {
  const { Component, router, pageProps, err } = props;

  useMount(() => {
    loadFonts();
  });

  const canUseDom = ExecutionEnvironment.canUseDOM;

  if (!canUseDom && CLIENT_ONLY.includes(router.pathname)) {
    return null;
  }

  const isLanding = router.pathname === '/' || router.pathname === '/project-manager';

  return (
    <CacheProvider>
      <ConfigProvider locale={ruRU}>
        <UserProvider>
          <GlobalProvider>
            <Header isLanding={isLanding} />
            <Component {...pageProps} err={err} canUseDom={canUseDom} />
            <Footer isLanding={isLanding} />
            {!CONFIG.disableYMetrics && (
              <YMInitializer
                accounts={[71298025]}
                options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
                version="2"
              />
            )}
          </GlobalProvider>
        </UserProvider>
      </ConfigProvider>
    </CacheProvider>
  );
};

// MyApp.getInitialProps = async (appContext) {
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// }

export default MyApp;
