import { MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Link from 'next/link';

const { Item: MenuItem } = Menu;

const HeaderMenu = (props) => {
  const { pathname } = props;

  return (
    <Menu mode="horizontal" selectedKeys={[pathname]} overflowedIndicator={<MenuOutlined />}>
      <MenuItem key="/orders">
        <Link href="/orders">Заказы</Link>
      </MenuItem>
      <MenuItem key="/project-manager">
        <Link href="/project-manager">Персональный менеджер</Link>
      </MenuItem>
      {/* <MenuItem key="/executors">
        <Link href="/executors">Исполнители</Link>
      </MenuItem> */}
      <MenuItem key="/blog">
        <Link href="/blog">Статьи</Link>
      </MenuItem>
      <MenuItem key="/faq">
        <Link href="/faq">Вопросы и ответы</Link>
      </MenuItem>
      <MenuItem key="/documents">
        <Link href="/documents">Документы</Link>
      </MenuItem>
      {/* <MenuItem key="/contacts">Связаться с нами</MenuItem> */}
    </Menu>
  );
};

// Won't work outside of <Menu />. Hope the will fix it someday
// const LinkedMenuItem = (props) => {
//   const { children, link } = props;

//   return (
//     <MenuItem key={link}>
//       <Link href={link}>
//         <a>{children}</a>
//       </Link>
//     </MenuItem>
//   );
// };

export default HeaderMenu;
