import { UserOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import Link from 'next/link';
import { useContext } from 'react';
import { useFetcher } from 'rest-hooks';

import { GLOBAL_FORM_TYPE } from '@/helpers/constants';
import globalContext from '@/helpers/globalContext';
import { AuthResource, UserResource } from '@/resources';

require('./styles.less');

const { ROLE } = UserResource;

const HeaderUserMenu = (props) => {
  const { data: currentUser } = props;
  const { openModal, closeModal } = useContext(globalContext);
  const signOut = useFetcher(AuthResource.deleteShape(), true);

  useUpdateEffect(() => {
    if (currentUser) {
      closeModal();
    }
  }, [currentUser]);

  const handleOpenModal = (modal) => () => openModal(modal);

  const handleSignOut = () => {
    signOut({});
  };

  if (!currentUser) {
    return (
      <section className="user-menu">
        <Button className="user-menu__link-button" type="link" onClick={handleOpenModal(GLOBAL_FORM_TYPE.SIGN_IN)}>
          Войти
        </Button>
        <span className="user-menu__vertical-divider" />
        <Button className="user-menu__link-button" type="link" onClick={handleOpenModal(GLOBAL_FORM_TYPE.SIGN_UP)}>
          Регистрация
        </Button>
      </section>
    );
  }

  const { avatar, email, id, isVerified, name, role } = currentUser;

  return (
    <Dropdown
      className="user-menu"
      overlayClassName="user-menu__dropdown"
      overlay={<MenuDropdown id={id} onSignOut={handleSignOut} role={role} isVerified={isVerified} />}
      placement="bottomRight"
    >
      <div>
        <span className="user-menu__name">{name ?? email}</span>
        <Avatar className="user-menu__avatar" size={30} icon={<UserOutlined />} src={avatar} />
      </div>
    </Dropdown>
  );
};

const MenuDropdown = (props) => {
  const { id, isVerified, onSignOut, role } = props;

  return (
    <Menu selectedKeys={[]}>
      <Menu.Item key="new-order">
        <Link href="/order" as="/order">
          <a>
            <span>Создать заказ</span>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item key="my-orders">
        <Link href="/my-orders" as="/my-orders">
          <a>
            <span>Мои заказы</span>
          </a>
        </Link>
      </Menu.Item>
      {role !== ROLE.PERSON && isVerified && (
        <Menu.Item key="my-responses">
          <Link href="/my-responses" as="/my-responses">
            <a>
              <span>Мои отклики</span>
            </a>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="my-profile">
        {/* <Link href="/my-profile" as="/my-profile"> */}
        <Link href={`/user/${id}/profile`}>
          <a>
            <span>Профиль</span>
          </a>
        </Link>
      </Menu.Item>
      {isVerified && (
        <Menu.Item key="my-portfolio">
          {/* <Link href="/my-portfolio" as="/my-portfolio"> */}
          <Link href={`/user/${id}/portfolio`}>
            <a>
              <span>Портфолио</span>
            </a>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout" onClick={onSignOut}>
        <a>
          <span>Выход</span>
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default HeaderUserMenu;
